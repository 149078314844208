type Getter = (obj: Record<string, string>) => string;

export const translations: Record<string, Record<string, string | Getter>> = {
	en: {
		chat: 'Chat',
		feedbackText: 'Was this article helpful?',
		feedbackComplete: 'Thanks so much for your feedback!',
		searchPlaceholder: 'Search',
		emptyListSearch: ({ search }: Record<string, string>) => `Sorry, we didn't find any articles with the text "<b>${search}</b>". Try another query`,
		activeChat: 'Active chat',
		knowledgeBase: 'Knowledge Base',
		callbackRequest: 'Callback request',
		closedBy: 'Closed by',
		closedByGuest: 'Closed by guest',
		departments: 'Departments',
		disableSounds: 'Disable sounds',
		enableSounds: 'Enable sounds',
		endChat: 'End chat',
		enterYourMessage: 'Enter your message',
		fileTooLarge: 'Size limit is 20MB a file',
		fileInvalidType: 'Invalid file type',
		forwardedTo: ({ departmentOrOperatorName, from_operator_name }: Record<string, string>) => `${from_operator_name} forwarded to ${departmentOrOperatorName}`,
		joinedChat: 'joined the chat',
		leaveNumberToCallback: 'Please leave your number and we will call you back!',
		name: 'Name',
		email: 'email',
		operatorOffline: ({ operatorName }: Record<string, string>) => `${operatorName} is offline`,
		phone: 'Phone',
		poweredBy: 'Powered by',
		privacyPolicyAgreeText:
      'I agree that my personal data, provided via chat, will be processed for the purpose of receiving online',
		privacyPolicyLinkText: 'support and consultation.',
		rated: 'Rated',
		ratingWrite: 'Please rate your experience',
		sendMessageToStart: 'Send a message to start a conversation',
		sendUsMessage: 'Live chat',
		serverError: 'Server error occurred. Please try again later.',
		submit: 'Submit',
		teamIsOfflineText:
      'Our team is offline at the moment, you can text us using any of the available messengers. We will reply to you during our working hours',
		teamIsOfflineLiveChatOnlyText:
      'Our team is offline at the moment. We will reply to you during our working hours',
		toAddNewLine: 'to add a new line',
		'too-many-files': 'You can upload 1 file in a message',
		quickWayToContact: 'Here’s a quick way to contact us!',
		welcome: 'Welcome',
		writeIn: 'Write in',
		yourCallbackRequestHasBeenSent:
      'Your callback request has been successfully sent. We will call you back soon!',
	},
	uk: {
		chat: 'Чат',
		feedbackText: 'Чи була ця стаття корисною?',
		feedbackComplete: 'Велике спасибі за ваш відгук!',
		emptyListSearch: ({ search }: Record<string, string>) => `Вибачте, ми не знайшли жодної статті з текстом "<b>${search}</b>". Спробуйте інший запит`,
		searchPlaceholder: 'Пошук',
		activeChat: 'Активний чат',
		knowledgeBase: 'База Знань',
		callbackRequest: 'Запит на зворотний дзвінок',
		closedBy: 'Закрито',
		closedByGuest: 'Закрито гостем',
		departments: 'Відділи',
		disableSounds: 'Вимкнути звуки',
		enableSounds: 'Увімкнути звуки',
		endChat: 'Закрити чат',
		enterYourMessage: 'Введіть ваше повідомлення',
		fileTooLarge: 'Розмір перевищує 20 МБ',
		fileInvalidType: 'Неприпустимий тип файлу',
		forwardedTo: ({ departmentOrOperatorName, from_operator_name }: Record<string, string>) => `Передано ${departmentOrOperatorName} від ${from_operator_name}`,
		joinedChat: 'долучено до чату',
		leaveNumberToCallback: 'Будь ласка, залиште свій номер, і ми вам передзвонимо!',
		name: 'Ім’я',
		operatorOffline: ({ operatorName }: Record<string, string>) => `${operatorName} офлайн`,
		phone: 'Телефон',
		email: 'email',
		privacyPolicyAgreeText:
      'Я погоджуюся, що мої персональні дані, надані через чат, будуть оброблені з метою отримання онлайн',
		privacyPolicyLinkText: 'підтримки та консультації.',
		poweredBy: 'Powered by',
		rated: 'Оцінено на',
		ratingWrite: 'Як ви оцінюєте нашу роботу?',
		sendMessageToStart: 'Надішліть повідомлення, щоб почати розмову',
		sendUsMessage: 'Онлайн чат',
		serverError: 'Виникла помилка сервера. Будь ласка, спробуйте пізніше.',
		submit: 'Підтвердити',
		teamIsOfflineText:
      'Наша команда наразі офлайн, ви можете написати нам за допомогою будь-якого з доступних месенджерів. Ми відповімо вам у робочий час',
		teamIsOfflineLiveChatOnlyText: 'Наша команда наразі офлайн. Ми відповімо вам у робочий час',
		toAddNewLine: 'щоб додати новий рядок',
		'too-many-files': 'Не більше 1 файлу у повідомленні',
		quickWayToContact: 'Ось швидкий спосіб зв’язатися з нами!',
		welcome: 'Ласкаво просимо',
		writeIn: 'Написати в',
		yourCallbackRequestHasBeenSent:
      'Ваш запит на зворотний дзвінок успішно надіслано. Ми скоро вам зателефонуємо!',
	},
	ru: {
		chat: 'Чат',
		feedbackText: 'Была ли эта статья полезна?',
		feedbackComplete: 'Большое спасибо за ваш отзыв!',
		emptyListSearch: ({ search }: Record<string, string>) => `Извините, мы не нашли ни одной статьи с текстом "<b>${search}</b>". Попробуйте другой запрос`,
		searchPlaceholder: 'Поиск',
		activeChat: 'Активный чат',
		knowledgeBase: 'База Знаний',
		callbackRequest: 'Запрос на обратный звонок',
		closedBy: 'Закрыт',
		closedByGuest: 'Закрыт гостем',
		departments: 'Отделы',
		disableSounds: 'Выключить звуки',
		enableSounds: 'Включить звуки',
		endChat: 'Закрыть чат',
		enterYourMessage: 'Введите ваше сообщение',
		fileTooLarge: 'Размер превышает 20 МБ',
		fileInvalidType: 'Недопустимый тип файла',
		forwardedTo: ({ departmentOrOperatorName, from_operator_name }: Record<string, string>) => `Передано ${departmentOrOperatorName} от ${from_operator_name}`,
		joinedChat: 'присоединился к чату',
		leaveNumberToCallback: 'Пожалуйста, оставьте свой номер, и мы вам перезвоним!',
		name: 'Имя',
		operatorOffline: ({ operatorName }: Record<string, string>) => `${operatorName} не в сети`,
		phone: 'Телефон',
		email: 'email',
		privacyPolicyAgreeText:
      'Я согласен с тем, что мои персональные данные, предоставленные через чат, будут обрабатываться с целью получения онлайн',
		privacyPolicyLinkText: 'поддержки и консультации.',
		poweredBy: 'Powered by',
		rated: 'Оценено на',
		ratingWrite: 'Как вы оцениваете нашу работу?',
		sendMessageToStart: 'Отправьте сообщение, чтобы начать разговор',
		sendUsMessage: 'Онлайн чат',
		serverError: 'Произошла ошибка сервера. Пожалуйста, попробуйте позже.',
		submit: 'Подтвердить',
		teamIsOfflineText:
      'Наша команда сейчас не в сети, вы можете написать нам в любой из доступных мессенджеров. Мы ответим вам в рабочее время',
		teamIsOfflineLiveChatOnlyText: 'Наша команда сейчас не в сети. Мы ответим вам в рабочее время',
		toAddNewLine: 'чтобы добавить нижнюю строку',
		'too-many-files': 'Не более 1 файла в сообщении',
		quickWayToContact: 'Быстрый способ связаться с нами!',
		welcome: 'Добро пожаловать',
		writeIn: 'Написать в',
		yourCallbackRequestHasBeenSent:
      'Ваш запрос на обратный звонок успешно отправлен. Мы скоро вам перезвоним!',
	},
};