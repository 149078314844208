import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

const resizeOut = ({ width, height, fill }: IPropsSvgIcon) => {
	const theme = useTheme();

	return (
		<svg
			width={width || 20}
			height={height || 20}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g
				clipPath="url(#clip0_1603_12539)"
			>
				<path
					d="M8.2857 8.2857L2.57141 2.57141"
					stroke={fill || theme.color.general.white}
					strokeWidth="1.14286"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M7.14284 2.57141H2.57141V7.14284"
					stroke={fill || theme.color.general.white}
					strokeWidth="1.14286"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M11.7142 11.7142L17.4285 17.4285"
					stroke={fill || theme.color.general.white}
					strokeWidth="1.14286"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M12.8572 17.4286H17.4286V12.8572"
					stroke={fill || theme.color.general.white}
					strokeWidth="1.14286"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_1603_12539">
					<rect
						width="16"
						height="16"
						fill="white"
						transform="translate(2 2)"
					/>
				</clipPath>
			</defs>
		</svg>

	);
};

export default resizeOut;