import React from 'react';
import { IPropsSvgIcon } from '../../types';

export const callBackIcon = ({
	width, height,
}: IPropsSvgIcon) => (
	<svg width={width || 16} height={height || 16} fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="m14.3126 11.4733-3.1552-1.8932c-.4658-.2795-1.0672-.1163-1.3294.3567l-.6399 1.1545a.1365.1365 0 0 1-.187.0528c-1.6467-.9495-3.1878-2.4888-4.1398-4.1361a.1365.1365 0 0 1 .052-.187l1.1562-.6487a.9564.9564 0 0 0 .3522-1.327l-1.8946-3.158c-.2669-.4447-.8495-.5957-1.2988-.3365-1.4688.8472-1.3806.79-1.4337.8427-1.7926 1.779-.431 5.8437 2.8687 9.1436 3.2999 3.2999 7.3654 4.6603 9.1432 2.8688.0531-.0535-.0034.0332.8427-1.4338.2592-.4493.1082-1.0319-.3366-1.2988Z" stroke="#fff" strokeWidth="1.14" />
		<path d="m14.8798 2.7607-1.6405-1.6406a.4101.4101 0 0 0-.58 0l-2.6084 2.6084-.8994-.8994c-.2441-.2441-.6634-.0946-.698.2489l-.4512 4.4707a.4103.4103 0 0 0 .4453.4496l4.5115-.4102c.346-.0314.4981-.4532.2529-.6985l-.9405-.9404 2.6083-2.6084a.41.41 0 0 0 0-.58Z" stroke="#fff" strokeWidth="1.14" />
	</svg>
);