import React, { useContext } from 'react';
import styled from 'styled-components';
import { StyledHeader } from './styled';
import Icon from './atomic/Icon';
import Typography from './atomic/Typography';
import { useWidget } from '../WidgetProvider';
import SearchInput from './SearchInput';
import { LangContext } from '../LangProvider';

interface IProps {
	search?: string,
	setSearch?: (val: string) => void,
	goOneScreenBack?: () => void,
}

const Wrap = styled.div`
  width: 100%;
`;

const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const TitleWithButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const ButtonWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SearchWrap = styled.div`
  margin-top: 15px;
`;

const WidgetKnowledgeBaseHeader = (props: IProps) => {
	const {
		goOneScreenBack,
		search,
		setSearch,
	} = props;

	const {
		setWidgetOpened,
		isLargeScreen,
		setIsLargeScreen,
		knowledgeBaseArticleLink,
	} = useWidget();

	const getTranslate = useContext(LangContext);

	const onCloseWidget = () => {
		setWidgetOpened(false);
	};

	const toggleHeight = () => {
		setIsLargeScreen((prevState) => !prevState);
	};

	const handleClickLink = (linkUrlArg: string) => {
		const linkUrl = (`//${linkUrlArg}`).replace(/\/\/http/g, 'http');
		window.open(linkUrl, '_blank');
	};

	return (
		<StyledHeader>
			<Wrap>
				<TitleWrap>
					<TitleWithButton>
						{goOneScreenBack && <Icon name="arrowLeft" className="arrowLeft" onClick={goOneScreenBack} />}
						<Typography variant="h3" className="title">
							{getTranslate('knowledgeBase')}
						</Typography>
					</TitleWithButton>
					<ButtonWrap>
						{knowledgeBaseArticleLink ? (
							<Icon onClick={() => handleClickLink(knowledgeBaseArticleLink)} name="articleLink" className="closeIcon" />
						) : null}
						<Icon onClick={toggleHeight} name={isLargeScreen ? 'resizeIn' : 'resizeOut'} className="closeIcon" />
						<Icon onClick={onCloseWidget} name="chevronDownThin" className="closeIcon" />
					</ButtonWrap>
				</TitleWrap>
				{typeof search === 'string' && setSearch ? (
					<SearchWrap>
						<SearchInput value={search} setValue={setSearch} />
					</SearchWrap>
				) : null}
			</Wrap>
		</StyledHeader>
	);
};

export default WidgetKnowledgeBaseHeader;