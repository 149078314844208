import React, { Dispatch, SetStateAction, useContext } from 'react';
import Button from '../../../components/atomic/Button';
import { StyledChannelsContainer } from '../styled';
import { BOTS_URLS_START } from '../../../constants';
import { IChannel, IResponseChatWidgetLicense } from '../../../types';
import { LangContext } from '../../../LangProvider';

interface IProps {
	channels: IResponseChatWidgetLicense['channels'],
	goOneScreenForward: () => void,
	setIsChannelButtonClicked: Dispatch<SetStateAction<string>>,
}

const CHANNEL_NAMES: Record<string, string> = {
	viber: 'Viber',
	telegram: 'Telegram',
	whatsapp: 'WhatsApp',
	facebook: 'Facebook Messenger',
	instagram: 'Instagram',
};

const Channels = (props: IProps) => {
	const {
		channels,
		goOneScreenForward,
		setIsChannelButtonClicked,
	} = props;

	const getTranslate = useContext(LangContext);
	const writeInTranslate = getTranslate('writeIn');
	const sendUsMessageTranslate = getTranslate('sendUsMessage');

	if (!channels) {
		return null;
	}

	const onMessengerClick = (channelKey: string, channel: IChannel) => () => {
		setIsChannelButtonClicked('messenger');

		if (channel?.bot_url) {
			return window.open(channel.bot_url, channelKey);
		}

		if (channelKey === 'instagram') {
			return window.open(`${BOTS_URLS_START.instagram}${channel.page_name}`, channelKey);
		}

		if (channelKey === 'whatsapp') {
			return window.open(`${BOTS_URLS_START.whatsapp}${channel.phone}`, channelKey);
		}

		return window.open(`${BOTS_URLS_START[channelKey]}${channel?.page_id || channel?.bot_name}`, channelKey);
	};

	const onLiveChatClick = () => {
		if (goOneScreenForward) {
			goOneScreenForward();
		}
		setIsChannelButtonClicked('live_chat');
	};

	return (
		<>
			<StyledChannelsContainer>
				{Object.keys(channels).map((channelKey) => {
					// @ts-ignore
					const channel = channels[channelKey];
					if (channel.status !== 'active' || channelKey === 'live_chat' || channelKey === 'callback') {
						return null;
					}

					return (
						<Button
							key={channelKey}
							fullWidth
							variant="general"
							className={channelKey}
							// @ts-ignore
							iconLeftProps={{ name: `${channelKey}Icon` }}
							onClick={onMessengerClick(channelKey, channel)}
						>
							{writeInTranslate}
							{' '}
							{CHANNEL_NAMES[channelKey] || ''}
						</Button>
					);
				})}
			</StyledChannelsContainer>
			{channels.live_chat.status === 'active' && (
				<Button iconLeftProps={{ name: 'chats' }} className="live_chat" onClick={onLiveChatClick}>
					{sendUsMessageTranslate}
				</Button>
			)}
		</>
	);
};

export default Channels;