import { useEffect } from 'react';

import {
	CHANNEL_BUTTON_HEIGHT,
	CHECKBOX_HEIGHT,
	CHECKBOX_HEIGHT_EN,
	CHOOSE_CHANNELS_FOOTER_HEIGHT,
	CHOOSE_CHANNELS_HEADER_HEIGHT,
	CHOOSE_CHANNELS_HEADER_HEIGHT_UK,
	CHOOSE_CHANNELS_HEADER_WITH_OFFLINE_HEIGHT,
	CONTACT_FORM_FOOTER_HEIGHT,
	CONTACT_FORM_HEADER_WITH_OFFLINE_HEIGHT,
	HEADER_HEIGHT_PRE_CHAT_FORM,
	HEADER_HEIGHT_PRE_CHAT_FORM_EN,
	HEADER_WITH_ARROW_HEIGHT_PRE_CHAT_FORM,
	INPUT_HEIGHT,
	KNOWLEDGE_BASE_ITEM_HEIGHT,
	KNOWLEDGE_BASE_ITEM_LARGE_HEIGHT,
	KNOWLEDGE_BASE_LIST_HEIGHT,
	KNOWLEDGE_BASE_LIST_LARGE_HEIGHT,
	LIVE_CHAT_HEIGHT, PAGINATION_TABS_HEIGHT,
	TEXTAREA_HEIGHT,
} from '../constants';
import { useWidget } from '../WidgetProvider';
import { IResponseChatWidgetLicense, ScreenType } from '../types';
import { getIsPresentKnowledgeBase } from '../utils/utils';

interface IProps {
	currentScreen: ScreenType,
	isOffline: boolean,
	screens: ScreenType[],
	widgetSettings: IResponseChatWidgetLicense,
	formFields: IResponseChatWidgetLicense['settings']['form_fields'],
}

export const useWidgetHeight = (props: IProps) => {
	const {
		currentScreen,
		isOffline,
		screens,
		widgetSettings,
		formFields,
	} = props;

	const {
		widgetOpened,
		isLargeScreen,
		knowledgeBaseResponse,
		changeIframeWidth,
		changeLiveChatScreenHeight,
		changePreLiveChatScreenHeight,
	} = useWidget();

	const { language } = widgetSettings;
	const { is_hide_branding, privacy_policy } = widgetSettings?.settings || {};
	const channels = { ...widgetSettings?.channels };

	channels.callback = undefined;

	const getHeightForChooseChannels = () => {
		const heightBranding = is_hide_branding
			? 0
			: CHOOSE_CHANNELS_FOOTER_HEIGHT;

		const headerHeight = language === 'uk' ? CHOOSE_CHANNELS_HEADER_HEIGHT_UK : CHOOSE_CHANNELS_HEADER_HEIGHT;

		const heightOffline = isOffline
			? CHOOSE_CHANNELS_HEADER_WITH_OFFLINE_HEIGHT
			: headerHeight;

		const activeChannelsArray = Object
			.values(channels)
			.filter((channel) => channel?.status === 'active') || [];

		const isPresentKnowledgeBase = getIsPresentKnowledgeBase(knowledgeBaseResponse);

		const heightFromPaginationTabs = isPresentKnowledgeBase ? PAGINATION_TABS_HEIGHT : 0;

		const heightFromButtons = CHANNEL_BUTTON_HEIGHT * activeChannelsArray.length;

		return heightBranding
			+ heightOffline
			+ heightFromPaginationTabs
			+ heightFromButtons;
	};

	const getHeightPreChatForm = () => {
		const isOfflinePreChat = screens[0] === 'preChatForm' && isOffline;

		const fieldsVisible = Object.keys(formFields)
			// @ts-ignore
			.filter((key) => formFields[key].is_visible);

		const headerHeightPreChatForm = language === 'en' ? HEADER_HEIGHT_PRE_CHAT_FORM_EN : HEADER_HEIGHT_PRE_CHAT_FORM;
		const headerHeight = screens[0] === 'preChatForm'
			? headerHeightPreChatForm
			: HEADER_WITH_ARROW_HEIGHT_PRE_CHAT_FORM;

		const heightBranding = is_hide_branding ? 5 : CONTACT_FORM_FOOTER_HEIGHT;

		const heightVisibleFields = fieldsVisible.length * INPUT_HEIGHT;

		const isPresentKnowledgeBase = getIsPresentKnowledgeBase(knowledgeBaseResponse);

		const heightFromPaginationTabs = isPresentKnowledgeBase ? PAGINATION_TABS_HEIGHT : 0;

		const heightOffline = isOfflinePreChat
			? CONTACT_FORM_HEADER_WITH_OFFLINE_HEIGHT
			: headerHeight;

		const checkboxHeight = language === 'en' ? CHECKBOX_HEIGHT_EN : CHECKBOX_HEIGHT;
		const heightPrivacyPolicyIsRequired = privacy_policy?.is_required
			? checkboxHeight
			: 0;
		const additionalHeight = 20;

		return heightBranding
			+ heightVisibleFields
			+ heightOffline
			+ heightFromPaginationTabs
			+ heightPrivacyPolicyIsRequired
			+ TEXTAREA_HEIGHT
			+ additionalHeight;
	};

	const getHeightForKnowledgeBaseList = () => {
		if (isLargeScreen) {
			return KNOWLEDGE_BASE_LIST_LARGE_HEIGHT;
		}
		return KNOWLEDGE_BASE_LIST_HEIGHT;
	};

	const getHeightForKnowledgeBaseItem = () => {
		if (isLargeScreen) {
			return KNOWLEDGE_BASE_ITEM_LARGE_HEIGHT;
		}
		return KNOWLEDGE_BASE_ITEM_HEIGHT;
	};

	const getHeight = () => {
		if (currentScreen === 'chooseChannels') {
			return getHeightForChooseChannels();
		}
		if (currentScreen === 'preChatForm') {
			return getHeightPreChatForm();
		}
		if (currentScreen === 'liveChat') {
			return LIVE_CHAT_HEIGHT;
		}
		if (currentScreen === 'knowledgeBaseList') {
			return getHeightForKnowledgeBaseList();
		}
		if (currentScreen === 'knowledgeBaseItem') {
			return getHeightForKnowledgeBaseItem();
		}

		return undefined;
	};

	useEffect(() => {
		if (widgetOpened) {
			const height = getHeight();

			const width = isLargeScreen && (currentScreen === 'knowledgeBaseItem') ? 678 : 340;

			if (height) {
				if (currentScreen === 'liveChat') {
					changeLiveChatScreenHeight(height, width);
				} else {
					changePreLiveChatScreenHeight(height, width);
				}
			}

			changeIframeWidth(width);
		}
	}, [
		isOffline,
		screens,
		changeLiveChatScreenHeight,
		changePreLiveChatScreenHeight,
		currentScreen,
		is_hide_branding,
		widgetOpened,
		widgetSettings,
		formFields,
	]);
};