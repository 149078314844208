import React from 'react';
import { ThemeProvider, StyleSheetManager } from 'styled-components';
import isPropValid from '@emotion/is-prop-valid';

import { useWidget } from './WidgetProvider';
import GlobalStyle from './globalStyles';
import getTheme from './theme';
import CallbackIframe from './containers/CallbackIframe/CallbackIframe';
import WidgetButtonContainer from './containers/WidgetButtonContainer';
import TwoButtonsOnOneSideContainer from './containers/TwoButtonsOnOneSideContainer';
import LiveChatProvider from './LiveChatProvider';
import LangProvider from './LangProvider';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const shouldForwardProp = (propName: string, target: any) => {
	if (typeof target === 'string') {
		return isPropValid(propName);
	}
	return true;
};

function App() {
	const { iframeType, widgetSettings, isTwoButtonsOnOneSide } = useWidget();

	const theme = getTheme(
		widgetSettings?.settings?.color,
		iframeType,
	);

	const getMainContainer = () => {
		if (isTwoButtonsOnOneSide) {
			return <TwoButtonsOnOneSideContainer />;
		}
		if (iframeType === 'callback') {
			return <CallbackIframe />;
		}
		return (
			<LiveChatProvider>
				<WidgetButtonContainer />
			</LiveChatProvider>
		);
	};

	return (
		<StyleSheetManager
			shouldForwardProp={shouldForwardProp}
			enableVendorPrefixes
		>
			<ThemeProvider theme={theme}>
				<GlobalStyle />
				<LangProvider>
					{getMainContainer()}
				</LangProvider>
			</ThemeProvider>
		</StyleSheetManager>
	);
}

export default App;