import { useCallback } from 'react';
import { useWidget } from '../WidgetProvider';
import { translations } from '../translations';
import { Lang } from '../types';

const DEFAULT_LANG: Lang = 'uk';

const LIST_LANG: Lang[] = ['en', 'uk', 'ru'];

const getTranslatedString = (
	key: string,
	lang: Lang,
	args?: Record<string, string>,
) => {
	const result = translations[lang][key];

	if (typeof result === 'function') {
		return result(args!) || '';
	}

	if (typeof result === 'string') {
		return result;
	}

	if (process.env.NODE_ENV === 'development') {
		// eslint-disable-next-line no-console
		console.error('MISSING_VALUE_BY_LANG_KEY: ', key);
	}

	return '';
};

export const useTranslation = (key: string, args?: Record<string, string>) => {
	const { widgetSettings } = useWidget();
	const { language } = widgetSettings;

	const realLang = LIST_LANG
		.find((lang) => lang === language) || DEFAULT_LANG;

	const result = useCallback(
		() => getTranslatedString(key, realLang, args),
		[key, realLang, JSON.stringify(args)],
	);

	return result();
};