import React, { useContext } from 'react';
import Typography from '../../../components/atomic/Typography';
import FlexRow from '../../../components/atomic/FlexRow';
import Icon from '../../../components/atomic/Icon';
import LinkStyled from '../../../components/atomic/Link';
import { LangContext } from '../../../LangProvider';

const WidgetFooter = () => {
	const getTranslate = useContext(LangContext);
	const poweredByTranslate = getTranslate('poweredBy');

	return (
		<FlexRow
			className="widgetFooter"
			justifyContent="center"
			padding="10px 20px"
		>
			<Typography
				variant="body2"
				text={poweredByTranslate}
				margin="0 6px 0 0"
			/>
			<LinkStyled
				href="https://www.skibble.chat/"
				noStyles
			>
				<Icon
					name="skibbleTextLogo"
					fill="#FF5F00"
					height={10}
					width={84}
				/>
			</LinkStyled>
		</FlexRow>
	);
};

export default WidgetFooter;