import dayjs from 'dayjs';
import { Lang } from '../types';

const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');
const updateLocale = require('dayjs/plugin/updateLocale');

require('dayjs/locale/uk');
require('dayjs/locale/en');
require('dayjs/locale/ru');

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(updateLocale);

export const isObjectEmpty = (obj: object | undefined) => !obj || !Object.keys(obj).length;

export const isValidJSON = (string: string) => {
	try {
		JSON.parse(string);
	} catch (e) {
		return false;
	}
	return true;
};

export const transformDateWithDay = (date: string | Date, tLang: Lang) => {
	// @ts-ignore
	const timezone = dayjs.tz.guess();

	// @ts-ignore
	dayjs.updateLocale('uk', {
		weekdaysMin: ['Нд', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
	});
	// @ts-ignore
	dayjs.updateLocale('ru', {
		weekdaysMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
	});

	if (tLang === 'en') {
		// @ts-ignore
		return dayjs.utc(date).tz(timezone).locale(tLang).format('ddd, DD MMM HH:mm');
	}
	// @ts-ignore
	return dayjs.utc(date).tz(timezone).locale(tLang).format('dd, DD MMMM HH:mm');
};

export const storageAvailable = () => {
	let storage;
	try {
		storage = window.localStorage;
		const x = '__storage_test__';
		storage.setItem(x, x);
		storage.removeItem(x);
		return true;
	} catch (e) {
		return (
			e instanceof DOMException
      // everything except Firefox
      && (e.code === 22
        // Firefox
        || e.code === 1014
        // test name field too, because code might not be present
        // everything except Firefox
        || e.name === 'QuotaExceededError'
        // Firefox
        || e.name === 'NS_ERROR_DOM_QUOTA_REACHED')
      // acknowledge QuotaExceededError only if there's something already stored
      && storage
      && storage.length !== 0
		);
	}
};