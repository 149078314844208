import React, {
	Dispatch,
	SetStateAction,
	useEffect,
	useRef,
	useState,
} from 'react';
import styled, { useTheme } from 'styled-components';
import { IKnowledgeBaseFolder, ScreenType } from '../../../types';
import Article from './Article';
import Icon from '../../../components/atomic/Icon';

const TitleWrap = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 13px 12px;
  border-bottom: 1px solid ${({ theme }) => theme.color.general.lightest};
  border-radius: 3px;
  background-color: ${({ active, theme }) => (active ? theme.color.primary.main : theme.color.general.white)};
  color: ${({ theme, active }) => (active ? theme.color.general.white : theme.color.general.darkest)};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme, active }) => (active ? theme.color.primary.main : theme.color.primary.lightest)};
  }
`;

const ArticlesBlock = styled.div<{ active: boolean }>`
  overflow: hidden;
  transition: height 300ms ease-in-out;
`;

const FolderIconWithTitle = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
`;

const FolderIcon = styled.div`
  position: absolute;
`;

const Title = styled.div`
  padding: 0 5px 0 28px;
  font-size: 14px;
  line-height: 18px;
  overflow-wrap: anywhere;
`;

const ArrowIcon = styled.div<{active: boolean}>`
  transition: all ease-in 300ms;
  transform: ${({ active }) => (active ? 'rotate(-90deg)' : 'rotate(0deg)')};
`;

interface IProps extends IKnowledgeBaseFolder {
	setCurrentScreen: Dispatch<SetStateAction<ScreenType>>;
	hasSearch: boolean;
}

const cacheActiveRecord: Record<string, boolean> = {};

const Folder = (props: IProps) => {
	const {
		name,
		hasSearch,
		articles,
		setCurrentScreen,
		id,
	} = props;

	const keyForCache = hasSearch ? `search_${id}` : id;

	const [active, setActive] = useState<boolean>(cacheActiveRecord[keyForCache] || false);
	const theme = useTheme();

	const contentHeight = useRef<HTMLDivElement>(null);

	const handleClick = () => {
		setActive(!active);
		cacheActiveRecord[keyForCache] = !active;
	};

	useEffect(() => {
		if (!hasSearch) {
			Object.keys(cacheActiveRecord).forEach((key) => {
				if (key.includes('search')) {
					cacheActiveRecord[key] = false;
				}
			});
		}

		setActive(cacheActiveRecord[keyForCache] || false);
	}, [hasSearch]);

	return (
		<div>
			<TitleWrap active={active} onClick={handleClick}>
				<FolderIconWithTitle>
					<FolderIcon>
						<Icon
							name="folder"
							stroke={active ? theme.color.general.white : undefined}
							fill={active ? `${theme.color.general.white}40` : `${theme.color.primary.main}40`}
						/>
					</FolderIcon>
					<Title>{name}</Title>
				</FolderIconWithTitle>
				<ArrowIcon active={active}>
					<Icon
						name="chevronDownThin"
						fill={active ? `${theme.color.general.white}` : `${theme.color.general.darkest}`}
					/>
				</ArrowIcon>
			</TitleWrap>

			<ArticlesBlock
				active={active}
				ref={contentHeight}
				style={{ height: (active ? contentHeight.current?.scrollHeight : '0px') }}
			>
				{articles.map((article) => (
					<Article
						key={article.id}
						id={article.id}
						title={article.title}
						additionalPadding
						setCurrentScreen={setCurrentScreen}
					/>
				))}
			</ArticlesBlock>
		</div>
	);
};

export default Folder;