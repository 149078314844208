import React, { useContext, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import Icon from './atomic/Icon';
import { LangContext } from '../LangProvider';

interface IProps {
	value: string;
	setValue: (val: string) => void;
}

const Wrap = styled.div`
  position: relative;
`;

const IconBlock = styled.div`
  position: absolute;
  top: 12px;
  left: 10px;
`;

const InputBlock = styled.input<{ isActive: boolean }>`
  width: 100%;
  padding: 12px 10px 12px 35px;
  border: none;
  border-radius: 5px;
  background-color: ${({ theme, isActive }) => (isActive ? theme.color.general.white : `${theme.color.general.white}40`)};
  color: ${({ theme }) => theme.color.general.black};
  font-size: 16px;
  outline: none;

  &::placeholder {
    color: ${({ theme }) => theme.color.general.white};
  }
  
  &:focus {
    background-color: ${({ theme }) => theme.color.general.white};

    &::placeholder {
      color: ${({ theme }) => theme.color.general.black};
    }
  }
  
  &::-webkit-search-cancel-button {
    width: 20px;
    height: 20px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxnIGlkPSJjbG9zZSI+CiAgICAgICAgPHBhdGggeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBmaWxsPSIjZmZmIiBpZD0ieCIgZD0iTTE4LjcxNyA2LjY5N2wtMS40MTQtMS40MTQtNS4zMDMgNS4zMDMtNS4zMDMtNS4zMDMtMS40MTQgMS40MTQgNS4zMDMgNS4zMDMtNS4zMDMgNS4zMDMgMS40MTQgMS40MTQgNS4zMDMtNS4zMDMgNS4zMDMgNS4zMDMgMS40MTQtMS40MTQtNS4zMDMtNS4zMDN6Ii8+CiAgICA8L2c+Cjwvc3ZnPg==");
    background-size: 20px 20px;
    appearance: none;
  }
`;
const SearchInput = (props: IProps) => {
	const {
		value,
		setValue,
	} = props;

	const [focused, setFocused] = useState(false);

	const theme = useTheme();
	const getTranslate = useContext(LangContext);
	const searchPlaceholderTranslate = getTranslate('searchPlaceholder');

	return (
		<Wrap>
			<IconBlock>
				<Icon name="search" fill={focused || value ? theme.color.general.black : theme.color.general.white} className="icon-search" />
			</IconBlock>
			<InputBlock
				onFocus={() => setFocused(true)}
				onBlur={() => setFocused(false)}
				type="search"
				isActive={!!value}
				placeholder={searchPlaceholderTranslate}
				value={value}
				onChange={(e) => setValue(e.target.value)}
			/>
		</Wrap>
	);
};

export default SearchInput;