import React, { Dispatch, SetStateAction, useContext } from 'react';
import styled from 'styled-components';
import Icon from './atomic/Icon';
import { ScreenType } from '../types';
import { useWidget } from '../WidgetProvider';
import { LangContext } from '../LangProvider';

const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 0 10px;
  box-shadow: 0 -14px 15px 0 rgb(0 0 0 / 5%);
`;

const Tab = styled.div`
  flex: 1;
  flex-direction: column;
  padding: 7px 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.general.lightest};
  cursor: pointer;
  gap: 5px;
  
  &:not(:last-child) {
    border-right: 1px solid ${({ theme }) => theme.color.general.lightest};
  }
`;

const Text = styled.div<{active?: boolean}>`
  color: ${({ theme, active }) => (active ? theme.color.primary.main : theme.color.general.black)};
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  line-height: 23px;
`;

interface IProps {
	screens: ScreenType[],
	currentScreen: ScreenType,
	setCurrentScreen: Dispatch<SetStateAction<ScreenType>>
}

const PaginationTabs = (props: IProps) => {
	const {
		screens,
		currentScreen,
		setCurrentScreen,
	} = props;

	const { knowledgeBaseItemId } = useWidget();
	const getTranslate = useContext(LangContext);

	const isShowTabs = screens.length > 2 && screens.includes('knowledgeBaseList');

	const isActiveKnowledgeBase = currentScreen === 'knowledgeBaseItem' || currentScreen === 'knowledgeBaseList';

	const isActiveChat = !isActiveKnowledgeBase;

	const handleClickActiveChat = () => (isActiveChat ? null : setCurrentScreen(screens[0]));
	const handleClickActiveKnowledgeBase = () => {
		if (!isActiveKnowledgeBase) {
			setCurrentScreen(knowledgeBaseItemId ? 'knowledgeBaseItem' : 'knowledgeBaseList');
		}
	};

	const chatTranslate = getTranslate('chat');
	const knowledgeBaseTranslate = getTranslate('knowledgeBase');

	if (!isShowTabs) {
		return null;
	}

	return (
		<Wrap>
			<Tab onClick={handleClickActiveChat}>
				<Icon
					name={isActiveChat
						? 'chatPaginationActive'
						: 'chatPagination'}
				/>
				<Text active={isActiveChat}>
					{!isShowTabs && chatTranslate}
				</Text>
			</Tab>
			<Tab onClick={handleClickActiveKnowledgeBase}>
				<Icon
					name={isActiveKnowledgeBase
						? 'knowledgeBasePaginationActive'
						: 'knowledgeBasePagination'}
				/>
				<Text active={isActiveKnowledgeBase}>
					{!isShowTabs && knowledgeBaseTranslate}
				</Text>
			</Tab>
		</Wrap>
	);
};

export default PaginationTabs;