import React from 'react';
import { IPropsSvgIcon } from '../../types';

export const whatsappIcon = ({
	width, height,
}: IPropsSvgIcon) => (
	<svg width={width || 20} height={height || 20} fill="none" viewBox="0 0 20 20">
		<circle cx="10" cy="10" r="10" fill="#25D366" />
		<path d="M10.0016 3.75H9.99844C6.55235 3.75 3.75 6.55313 3.75 10C3.75 11.3672 4.19063 12.6344 4.93984 13.6633L4.16094 15.9852L6.56328 15.2172C7.55156 15.8719 8.73047 16.25 10.0016 16.25C13.4477 16.25 16.25 13.4461 16.25 10C16.25 6.55391 13.4477 3.75 10.0016 3.75ZM13.6383 12.5758C13.4875 13.0016 12.8891 13.3547 12.4117 13.4578C12.0852 13.5273 11.6586 13.5828 10.2227 12.9875C8.38594 12.2266 7.20313 10.3602 7.11094 10.2391C7.02266 10.118 6.36875 9.25078 6.36875 8.35391C6.36875 7.45703 6.82422 7.02031 7.00781 6.83281C7.1586 6.67891 7.40781 6.60859 7.64688 6.60859C7.72422 6.60859 7.79375 6.6125 7.85625 6.61563C8.03985 6.62344 8.13203 6.63438 8.25313 6.92422C8.40391 7.2875 8.7711 8.18438 8.81485 8.27656C8.85938 8.36875 8.90391 8.49375 8.84141 8.61485C8.78281 8.73985 8.73125 8.79531 8.63906 8.90156C8.54688 9.00781 8.45938 9.08906 8.36719 9.20313C8.28281 9.30235 8.1875 9.4086 8.29375 9.59219C8.4 9.77188 8.76719 10.3711 9.30781 10.8523C10.0055 11.4734 10.5711 11.6719 10.7734 11.7563C10.9242 11.8188 11.1039 11.8039 11.2141 11.6867C11.3539 11.5359 11.5266 11.2859 11.7023 11.0398C11.8273 10.8633 11.9852 10.8414 12.1508 10.9039C12.3195 10.9625 13.2125 11.4039 13.3961 11.4953C13.5797 11.5875 13.7008 11.6313 13.7453 11.7086C13.7891 11.7859 13.7891 12.1492 13.6383 12.5758Z" fill="white" />
	</svg>
);