import React, { useContext } from 'react';
import styled from 'styled-components';
import { LangContext } from '../../../LangProvider';

interface IProps {
	search: string;
}

const Wrap = styled.div`
  padding: 10px;
`;

const Text = styled.div`
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  line-height: 18px;
`;

const EmptyList = ({ search }: IProps) => {
	const getTranslate = useContext(LangContext);
	const searchTranslate = getTranslate('emptyListSearch', { search });
	return (
		<Wrap>
			<Text dangerouslySetInnerHTML={{ __html: searchTranslate }} />
		</Wrap>
	);
};

export default EmptyList;