import React, { useContext, useId, useState } from 'react';

import { StyledRatingWrapper, StyledRatingWrite } from './styled';
import Typography from '../Typography';
import Icon from '../Icon';
import { LangContext } from '../../../LangProvider';

interface IProps {
	fill?: string,
	onStarClick: (index: number) => void;
}

const RatingWrite = (props: IProps) => {
	const { fill, onStarClick } = props;
	const [rating, setRating] = useState<number | null>(null);
	const id = useId();
	const getTranslate = useContext(LangContext);
	const ratingWriteTranslate = getTranslate('ratingWrite');

	const onRate = (index: number) => {
		setRating(index);

		if (onStarClick) {
			onStarClick(index);
		}
	};

	return (
		<StyledRatingWrapper>
			<Typography
				variant="button1"
				text={ratingWriteTranslate}
			/>
			<StyledRatingWrite>
				{[1, 2, 3, 4, 5].map((starId) => {
					const isFilled = starId <= (rating || 0);

					return (
						<Icon
							key={starId}
							name="starSign"
							fill={isFilled ? '#FFE9C2' : fill}
							width={20}
							height={20}
							id={`${id}_${starId}`}
							onClick={() => onRate(starId)}
						/>
					);
				})}
			</StyledRatingWrite>
		</StyledRatingWrapper>
	);
};

export default RatingWrite;