import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Popper } from 'react-popper';
import styled from 'styled-components';

const modifiers = {
	preventOverflow: {
		boundariesElement: 'viewport',
		padding: 10,
	},
};

interface IProps {
	tagName?: keyof JSX.IntrinsicElements,
	children: React.ReactNode,
	placement?: string,
	showDelay?: number,
	hideDelay?: number,
	tooltip?: string,
	tooltipClassName?: string,
	tooltipStyle: React.CSSProperties,
	tooltipMaxWidth?: string,
	hideArrow?: boolean,
	container?: string,
	arrowClassName?: string,
}

const Texty = (props: IProps) => {
	const {
		tagName: Tag,
		children,
		placement,
		showDelay,
		hideDelay,
		tooltip,
		tooltipClassName,
		tooltipStyle,
		tooltipMaxWidth,
		hideArrow,
		container,
		arrowClassName,
	} = props;

	const [isHovered, setIsHovered] = useState(false);
	const targetNode = useRef<React.Ref<HTMLDivElement>>(null);

	const content = tooltip || children;

	const extraStyle = tooltipMaxWidth
		? { ...tooltipStyle, maxWidth: tooltipMaxWidth }
		: tooltipStyle;

	const handleScroll = () => {
		setIsHovered(false);
	};

	const handleMouseEnter = () => {
		if (!showDelay) {
			setIsHovered(true);
			return undefined;
		}

		const showTimer = setTimeout(() => {
			setIsHovered(true);
		}, showDelay);

		return () => {
			clearTimeout(showTimer);
		};
	};

	const handleMouseEvent = (e: React.MouseEvent) => {
		e.stopPropagation();
	};

	const handleMouseLeave = () => {
		if (!hideDelay) {
			setIsHovered(false);
			return undefined;
		}

		const hideTimer = setTimeout(() => {
			setIsHovered(false);
		}, hideDelay);

		return () => {
			clearTimeout(hideTimer);
		};
	};

	const setTargetRef = (ref: React.Ref<HTMLDivElement>) => {
		targetNode.current = ref;
	};

	const target = targetNode.current;
	// @ts-ignore
	const isTruncated = !!target && target.scrollWidth > target.offsetWidth;
	const showTooltip = isHovered && isTruncated;

	useEffect(() => {
		if (isHovered) {
			window.addEventListener('scroll', handleScroll, true);
			const listenTimer = setTimeout(() => {
				window.addEventListener('scroll', handleScroll, true);
			}, 50);
			return () => {
				clearTimeout(listenTimer);
				window.removeEventListener('scroll', handleScroll, true);
			};
		}
		return () => {
			window.removeEventListener('scroll', handleScroll, true);
		};
	}, [isHovered]);

	return (
		// @ts-ignore
		<Tag
			ref={setTargetRef}
			data-texty={showTooltip}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			/* eslint-disable-next-line react/jsx-props-no-spreading */
			{...props}
		>
			{children}
			{showTooltip && (
				// @ts-ignore
				<Popper
					referenceElement={target}
					placement={placement}
					modifiers={modifiers}
				>
					{({
						ref, style, placement, arrowProps,
					}) => ReactDOM.createPortal(
						<div
							ref={ref}
							data-texty-tooltip={placement}
							className={tooltipClassName}
							style={extraStyle ? { ...style, ...extraStyle } : style}
							onClick={handleMouseEvent}
							onDoubleClick={handleMouseEvent}
							onContextMenu={handleMouseEvent}
							onMouseDown={handleMouseEvent}
							onMouseUp={handleMouseEvent}
						>
							{content}
							{!hideArrow && (
								<div
									ref={arrowProps.ref}
									data-texty-arrow={placement}
									className={arrowClassName}
									style={arrowProps.style}
								/>
							)}
						</div>,
						// @ts-ignore
						container || targetNode.current?.ownerDocument.body,
					)}
				</Popper>
			)}
		</Tag>
	);
};

Texty.defaultProps = {
	tagName: styled.div`/* stylelint-disable no-empty-source */`,
	showDelay: 150,
	hideDelay: 150,
	hideArrow: false,
	placement: 'top',
};

export default Texty;