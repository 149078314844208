import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const typeCallback = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg
			id="Layer_3"
			data-name="Layer 3"
			width={width || 56}
			height={height || 56}
			viewBox="0 0 56 56"
			fill="none"
		>
			<defs>
				<style>
					{`
					.typeCallback-cls-3 { stroke: ${theme.color.general.white}; }
          			.typeCallback-cls-4 { fill: ${fill || theme.color.primary.main}; }
          			`}
				</style>
			</defs>
			<g id="Layer_4" data-name="Layer 4">
				<path
					className="typeCallback-cls-4"
					d="M1.06299 11.7729C1.79753 6.19042 6.19042 1.79753 11.7729 1.06299C22.5445 -0.354331 33.4555 -0.354331 44.2271 1.06299C49.8096 1.79753 54.2025 6.19042 54.937 11.7729C56.3543 22.5445 56.3543 33.4555 54.937 44.2271C54.2025 49.8096 49.8096 54.2025 44.2271 54.937C33.4555 56.3543 22.5445 56.3543 11.7729 54.937C6.19042 54.2025 1.79753 49.8096 1.06299 44.2271C-0.354331 33.4555 -0.354331 22.5445 1.06299 11.7729Z"
				/>
				<path
					d="M40.6253 34.9466L34.3148 31.1602C33.3833 30.6011 32.1804 30.9275 31.6561 31.8735L30.3762 34.1826C30.3023 34.3158 30.1337 34.3641 30.002 34.2882C26.7088 32.3891 23.6266 29.3106 21.7225 26.0159C21.647 25.8853 21.6938 25.7167 21.8267 25.6422L24.1389 24.3445C25.0808 23.816 25.3991 22.6165 24.8435 21.6905L21.0542 15.3748C20.5204 14.4852 19.3553 14.1831 18.4567 14.7016C15.5191 16.396 15.6953 16.2816 15.5892 16.387C12.0039 19.945 14.7271 28.0743 21.3266 34.6741C27.9263 41.274 36.0574 43.9948 39.6131 40.4118C39.7191 40.3048 39.6062 40.4782 41.2984 37.5442C41.8167 36.6456 41.5148 35.4804 40.6253 34.9466Z"
					className="typeCallback-cls-3"
					strokeWidth="2"
				/>
				<path
					d="M41.7597 17.5215L38.4786 14.2402C38.1582 13.9199 37.6388 13.9199 37.3185 14.2402L32.1019 19.4571L30.3031 17.6582C29.8148 17.1699 28.9762 17.469 28.907 18.1559L28.0047 27.0973C27.953 27.6091 28.3808 28.0431 28.8951 27.9966L37.9182 27.1763C38.6102 27.1133 38.9144 26.2698 38.424 25.7793L36.5431 23.8984L41.7597 18.6816C42.0801 18.3613 42.0801 17.8418 41.7597 17.5215Z"
					className="typeCallback-cls-3"
					strokeWidth="2"
				/>
			</g>
		</svg>
	);
};