import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useTheme } from 'styled-components';
import ReactTexty from '../lib/Texty';

import { useLiveChat } from '../LiveChatProvider';
import { getFileUrl, mapEventWithFiles } from '../api';
import { FILE_TYPES } from '../constants';
import { transformDateWithDay } from '../utils';
import FilesList from './FilesList';
import FlexRow from './atomic/FlexRow';
import Icon from './atomic/Icon';
import TextPanel from './atomic/TextPanel';
import Typography from './atomic/Typography';
import Userpic from './atomic/Userpic';
import { StyledChatMessage } from './styled';
import { IChatEventBrief, IFileUploadResult } from '../types';
import { useWidget } from '../WidgetProvider';

interface IProps {
	action: IChatEventBrief,
	isSystemMessage: boolean,
	operatorOnline: boolean,
}

const getFileTypeFromName = (name?: string) => name?.split('.')?.pop()?.toLowerCase();

const getFiles = (files: IFileUploadResult[], types: Record<string, string[]>) => {
	if (!files || !Array.isArray(files)) {
		return undefined;
	}

	return files?.filter((file) => (file.type
		? Object.keys(types).includes(file.type)
		: Object.values(types)
			.flat()
			.includes(`.${getFileTypeFromName(file.file_name)}`)));
};

const MessageItem = (props: IProps) => {
	const { action, isSystemMessage, operatorOnline } = props;
	const {
		at,
		operator_name,
		operator_pic,
		data: { text, files },
		type,
	} = action;

	const theme = useTheme();
	const isMessageFromOperator = type === 'operator_message';
	const showBotAvatar = isSystemMessage && !operator_name;

	const messageFiles = getFiles(files, FILE_TYPES.files);
	const messageImages = getFiles(files, FILE_TYPES.images);

	const { setHistory } = useLiveChat();

	const { widgetSettings } = useWidget();
	const { language } = widgetSettings;

	useEffect(() => {
		const filesWithImg = files?.filter((file) => file?.file_code && !file?.url);

		if (filesWithImg?.length) {
			mapEventWithFiles(filesWithImg, action)
				// @ts-ignore
				.then((res: IChatEventBrief) => {
					setHistory((prev) => (
						prev.map((item) => (item?.id === action?.id ? res : item))
					));
				});
		}
	}, [files, setHistory, action]);

	useEffect(() => {
		if (operator_pic && !operator_pic?.startsWith('http')) {
			getFileUrl(null, 'operator_pic', operator_pic).then((res) => {
				setHistory((prev) => prev.map((item) => (operator_pic === item.operator_pic
					// @ts-ignore
					? { ...item, operator_pic: res.operator_pic }
					: item)));
			});
		}
	}, [operator_pic, setHistory]);

	const getTitle = () => (
		<>
			{(isMessageFromOperator || isSystemMessage) && operator_name && (
				<Typography
					text={operator_name || ''}
					as={ReactTexty}
					variant="caption1"
					style={{ maxWidth: '130px' }}
				/>
			)}
			{(isMessageFromOperator || isSystemMessage) && operator_name && (
				<Icon name="skibbleIcon" width={14} height={14} />
			)}
			<Typography
				variant="body2"
				text={transformDateWithDay(at, language)}
				color={theme.color.general.dark}
				fontSize="13px"
			/>
		</>
	);

	const urlify = (text: string) => {
		const currText = Array.isArray(text) ? text[0] : text;
		const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
		return currText?.replace(urlRegex, (url: string, b: string, c: string) => {
			const url2 = c === 'www.' ? `http://${url}` : url;
			return `<a href="${url2}" target="_blank" class="noStyles link">${url}</a>`;
		});
	};

	return (
		<StyledChatMessage
			className={clsx((isMessageFromOperator || isSystemMessage) && 'operatorMessage')}
		>
			<FlexRow alignItems="start" className={clsx(text && 'withText', 'messageRow')}>
				{(isMessageFromOperator || isSystemMessage) && operator_pic && (
					<Userpic height={36} width={36} src={operator_pic} operatorOnline={operatorOnline} />
				)}
				{showBotAvatar && <Icon margin="0 10px 0 0" name="bot" />}
				<TextPanel
					title={getTitle()}
					// eslint-disable-next-line react/no-danger
					content={(
						<>
							{text
								// eslint-disable-next-line react/no-danger
								&& <div dangerouslySetInnerHTML={{ __html: urlify(text) }} />}
							{!!messageImages?.length && <FilesList files={messageImages} type="images" />}
						</>
					)}
					className="chatMessage"
					padding="0"
				/>
			</FlexRow>
			{!!messageFiles?.length && <FilesList files={messageFiles} type="files" />}
		</StyledChatMessage>
	);
};

export default MessageItem;