import React, { useContext, useEffect } from 'react';
import { useTheme } from 'styled-components';
import ReactTexty from '../lib/Texty';

import { transformDateWithDay } from '../utils';
import Icon from './atomic/Icon';
import Typography from './atomic/Typography';
import { StyledAction, StyledActionWrapper, StyledLine } from './styled';
import { getFileUrl } from '../api';
import { useLiveChat } from '../LiveChatProvider';
import { IChatEventBrief } from '../types';
import { LangContext } from '../LangProvider';
import { useWidget } from '../WidgetProvider';

interface IProps {
	action: IChatEventBrief,
}

type Types = 'forwarded_operator' | 'forwarded_department' | 'operator_rated';

const ChatActionDivider = (props: IProps) => {
	const {
		action: {
			at,
			type,
			operator_name,
			operator_id,
			operator_pic,
			data,
		},
	} = props;

	const { setHistory } = useLiveChat();
	const theme = useTheme();
	const { widgetSettings } = useWidget();
	const getTranslate = useContext(LangContext);

	const { language } = widgetSettings;

	const {
		closed_by,
		rating,
		from_operator_name,
		to_operator_name,
		to_department_name,
		operator_name: operatorNameFromData,
	} = data;

	const borderColor: Record<Types, string> = {
		forwarded_operator: theme.color.tags.light.orange,
		forwarded_department: theme.color.tags.light.orange,
		operator_rated: theme.color.secondary.lighter,
	};

	const isChatForwarded = type === 'forwarded_operator' || type === 'forwarded_department';

	const timestamp = transformDateWithDay(at, language);

	useEffect(() => {
		if (type === 'operator_joined' && operator_id && !operator_pic?.startsWith('http')) {
			getFileUrl(null, 'operator_pic', operator_pic)
				.then((res) => {
					setHistory((prev) => prev.map((item) => (
						operator_pic === item.operator_pic
							// @ts-ignore
							? { ...item, operator_pic: res.operator_pic }
							: item
					)));
				});
		}
	}, [type, operator_id, operator_pic, setHistory]);

	const joinedChatTranslate = getTranslate('joinedChat');
	const forwardedToTranslate = getTranslate('forwardedTo', {
		from_operator_name,
		departmentOrOperatorName: to_department_name || to_operator_name,
	});
	const closedByTranslate = getTranslate('closedBy');
	const closedByGuestTranslate = getTranslate('closedByGuest');
	const ratedTranslate = getTranslate('rated');

	const getContent = () => {
		if (type === 'operator_joined') {
			return (
				<div className="actionDividerContainer">
					<Typography
						variant="caption3"
						text={operatorNameFromData || operator_name}
						as={ReactTexty}
						placement="top-end"
						className="longText"
						tooltipMaxWidth={110}
					/>
					<Icon name="skibbleIcon" width={14} height={14} />
					<Typography variant="caption3" text={` ${joinedChatTranslate} • ${timestamp}`} />
				</div>
			);
		}

		if (isChatForwarded) {
			return (
				<div className="actionDividerContainer">
					<Icon name="forwarded" fill="#FF5F00" />
					<Typography
						variant="caption3"
						color="#FF5F00"
						text={`${forwardedToTranslate}`}
						as={ReactTexty}
						className="longerText"
					/>
					<Typography variant="caption3" color="#FF5F00" text={`• ${timestamp}`} />
				</div>
			);
		}

		if (type === 'chat_closed') {
			return (
				<div className="actionDividerContainer">
					<Icon name="statusActive" width={14} height={14} stroke={theme.color.general.dark} fill="none" />
					<Typography variant="caption3" color={theme.color.general.dark} className="longerText">
						{closed_by === 'operator' ? (
							<>
								{closedByTranslate}
								{' '}
								<Typography
									margin="0 3px"
									as={ReactTexty}
									variant="caption3"
									text={operatorNameFromData || operator_name}
								/>
								•
								{'  '}
								{timestamp}
							</>
						) : (
							`${closedByGuestTranslate} • ${timestamp}`
						)}
					</Typography>
				</div>
			);
		}

		if (type === 'operator_rated') {
			return (
				<div className="actionDividerContainer">
					<Icon name="starSign" width={14} height={14} fill="#FFE9C2" />
					<Typography variant="caption3" color={theme.color.secondary.dark} className="longerText">
						{`${ratedTranslate} ${rating} → `}
						<Typography
							margin="0 3px"
							as={ReactTexty}
							variant="caption3"
							text={operatorNameFromData || operator_name}
						/>
						•
						{'  '}
						{timestamp}
					</Typography>
				</div>
			);
		}

		return null;
	};

	return (
		<StyledActionWrapper>
			<StyledLine />
			<StyledAction borderColor={borderColor[type as Types] || theme.color.general.lighter}>
				{getContent()}
			</StyledAction>
			<StyledLine />
		</StyledActionWrapper>
	);
};

export default ChatActionDivider;