import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';

import clsx from 'clsx';
import { StyledChatsWrapper } from '../../components/styled';
import LiveChatContainer from '../LiveChat/LiveChatContainer';
import { useWidget } from '../../WidgetProvider';
import { useLiveChat } from '../../LiveChatProvider';
import ChooseChannels from '../ChooseChannels';
import PreChatForm from '../PreChatForm';
import { useWidgetReceivingWebsocket } from '../../hooks/useWidgetWebsocket';
import { useSystemMessagesWithTimeouts } from '../../hooks/useSystemMessagesWithTimeouts';
import { useWidgetHeight } from '../../hooks/useWidgetHeight';
import { ScreenType } from '../../types';
import KnowledgeBaseList from '../KnowledgeBaseList.tsx/KnowledgeBaseList';
import KnowledgeBaseArticle from '../KnowledgeBaseArticle.tsx/KnowledgeBaseArticle';
import { getIsPresentKnowledgeBase } from '../../utils/utils';

const isBetween = require('dayjs/plugin/isBetween');

dayjs.extend(isBetween);

interface IProps {
	className?: string,
}

const WidgetButtonForms = (props: IProps) => {
	const { className } = props;

	const {
		license,
		contactUid,
		widgetOpened,
		setWidgetOpened,
		knowledgeBaseResponse,
		chatDetails,
		isNonWorkingTime,
		widgetSettings,
		isPreChatFormFilled,
	} = useWidget();
	const {
		isAllMessagesSeen, currentHistoryCount, history, onSetIsAllMessagesSeen,
	} = useLiveChat();

	const isLiveChatActive = widgetSettings?.channels?.live_chat?.status === 'active';

	const isMounted = useRef(false);

	const [screens, setScreens] = useState<ScreenType[]>([]);
	const [currentScreen, setCurrentScreen] = useState<ScreenType>('');

	useWidgetReceivingWebsocket();

	const { setIsChannelButtonClicked } = useSystemMessagesWithTimeouts({
		auto_reply_settings: widgetSettings.auto_reply_settings || {},
		channels: widgetSettings.channels,
		form_fields: widgetSettings.settings.form_fields,
		is_form_displayed: widgetSettings.settings.is_form_displayed,
		setCurrentScreen,
		isNonWorkingTime,
		currentScreen,
	});

	const {
		is_hide_branding,
		button_placement,
		is_form_displayed,
		form_fields,
		privacy_policy,
	} = widgetSettings.settings;

	useEffect(() => {
		if (chatDetails) {
			isMounted.current = false;
			setCurrentScreen(history?.length ? 'liveChat' : 'preChatForm');
		}
	}, [chatDetails]);

	useEffect(() => {
		if (chatDetails && !isMounted.current && !widgetOpened) {
			setWidgetOpened(true);
		}
	}, [chatDetails]);

	const isOnlyLiveChatActive = (isLiveChatActive
		&& !Object.keys(widgetSettings.channels).some((channel) => channel !== 'callback'
			&& channel !== 'live_chat'
			// @ts-ignore
			&& widgetSettings.channels[channel].status === 'active'));

	const isPreChatDisplay = is_form_displayed && currentHistoryCount === 0;

	useEffect(() => {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const parentWidth = urlParams.get('width');
		if (parentWidth && parseInt(parentWidth, 10) < 481) {
			const textareas = document.getElementsByTagName('textarea');
			const inputs = document.querySelectorAll('input[type="text"]');

			for (let i = 0; i < textareas.length; i += 1) {
				textareas[i].style.fontSize = '16px';
				textareas[i].style.lineHeight = '20px';
			}

			for (let i = 0; i < inputs.length; i += 1) {
				// @ts-ignore
				inputs[i].style.fontSize = '16px';
				// @ts-ignore
				inputs[i].style.lineHeight = '20px';
			}
		}
	}, [widgetOpened]);

	const getOfflineText = () => {
		if (isNonWorkingTime) {
			return isOnlyLiveChatActive ? 'teamIsOfflineLiveChatOnlyText' : 'teamIsOfflineText';
		}
		return '';
	};

	useWidgetHeight({
		currentScreen,
		isOffline: !!getOfflineText(),
		screens,
		widgetSettings,
		formFields: form_fields,
	});

	useEffect(() => {
		if (currentHistoryCount === null) {
			return;
		}

		const screensLocal: ScreenType[] = [];

		if (widgetSettings?.channels?.live_chat) {
			screensLocal.push('liveChat');
		}

		if (isPreChatDisplay && !isPreChatFormFilled) {
			screensLocal.unshift('preChatForm');
		}

		if (!isOnlyLiveChatActive) {
			screensLocal.unshift('chooseChannels');
		}

		if (getIsPresentKnowledgeBase(knowledgeBaseResponse)) {
			screensLocal.push('knowledgeBaseList');
			screensLocal.push('knowledgeBaseItem');
		}

		setScreens(screensLocal);

		if (!screensLocal.includes(currentScreen)) {
			setCurrentScreen(screensLocal[0] || '');
		}
	}, [
		currentHistoryCount,
		isOnlyLiveChatActive,
		isPreChatDisplay,
		isPreChatFormFilled,
		JSON.stringify(knowledgeBaseResponse),
	]);

	useEffect(() => {
		if (!isMounted.current && screens.length > 0 && !screens.includes(currentScreen)) {
			setCurrentScreen(screens[0]);
			isMounted.current = true;
		}
	}, [screens]);

	useEffect(() => {
		if (widgetOpened && !isAllMessagesSeen && currentScreen === 'liveChat') {
			onSetIsAllMessagesSeen(history, true);
		}
	}, [history, widgetOpened, onSetIsAllMessagesSeen, isAllMessagesSeen, currentScreen]);

	const goOneScreenBack = () => {
		const currentIndex = screens.indexOf(currentScreen);
		setCurrentScreen(screens[currentIndex - 1]);
	};

	const goOneScreenForward = () => {
		const currentIndex = screens.indexOf(currentScreen);
		setCurrentScreen(screens[currentIndex + 1]);
	};

	const showChooseChannels = !isOnlyLiveChatActive;

	const goOneScreenPreChatForm = screens[0] === 'preChatForm' || !screens.includes('preChatForm')
		? undefined
		: goOneScreenBack;

	return (
		<StyledChatsWrapper
			button_placement={button_placement}
			id="chatWrapper"
			className={clsx('minimised', className, 'chatWrapper', button_placement)}
		>
			{showChooseChannels && currentScreen === 'chooseChannels' ? (
				<ChooseChannels
					screens={screens}
					currentScreen={currentScreen}
					setCurrentScreen={setCurrentScreen}
					hideWidget={() => setWidgetOpened(false)}
					goOneScreenForward={goOneScreenForward}
					className="chooseChannels"
					channels={widgetSettings?.channels}
					is_hide_branding={is_hide_branding}
					offlineText={getOfflineText()}
					setIsChannelButtonClicked={setIsChannelButtonClicked}
				/>
			) : null}

			{currentScreen === 'preChatForm' ? (
				<PreChatForm
					screens={screens}
					currentScreen={currentScreen}
					setCurrentScreen={setCurrentScreen}
					hideWidget={() => setWidgetOpened(false)}
					goOneScreenForward={goOneScreenForward}
					goOneScreenBack={goOneScreenPreChatForm}
					className="preChatForm"
					is_hide_branding={is_hide_branding}
					fields={form_fields}
					privacy_policy={privacy_policy}
					offlineText={screens[0] === 'preChatForm' && getOfflineText()}
				/>
			) : null}

			{currentScreen === 'liveChat' ? (
				<LiveChatContainer
					offlineText={screens[0] === 'liveChat' && getOfflineText()}
					currentScreen={currentScreen}
					goOneScreenBack={screens[0] === 'liveChat' ? undefined : goOneScreenBack}
					setCurrentScreen={setCurrentScreen}
					license={license}
					contactUid={contactUid}
					className="liveChat"
					is_hide_branding={is_hide_branding}
					auto_reply_settings={widgetSettings.auto_reply_settings}
					isNonWorkingTime={isNonWorkingTime}
				/>
			) : null}

			{currentScreen === 'knowledgeBaseList' ? (
				<KnowledgeBaseList
					screens={screens}
					currentScreen={currentScreen}
					className="knowledgeBaseList"
					is_hide_branding={is_hide_branding}
					setCurrentScreen={setCurrentScreen}
				/>
			) : null}

			{currentScreen === 'knowledgeBaseItem' ? (
				<KnowledgeBaseArticle
					screens={screens}
					currentScreen={currentScreen}
					setCurrentScreen={setCurrentScreen}
					goOneScreenBack={goOneScreenBack}
					className="knowledgeBaseItem"
					is_hide_branding={is_hide_branding}
				/>
			) : null}

		</StyledChatsWrapper>
	);
};

export default WidgetButtonForms;