import React from 'react';
import { useTheme } from 'styled-components';

import Icon from './atomic/Icon';
import Typography from './atomic/Typography';
import { StyledUploadedItem } from './styled';
import { formatSizeUnits } from '../utils/formatSizeUnits';
import { IFileUploadResult } from '../types';

interface IProps {
	file?: IFileUploadResult,
	deleteFile: () => void,
}

const UploadedItem = (props: IProps) => {
	const {
		file,
		deleteFile,
	} = props;

	const theme = useTheme();

	if (!file || !file.file_name) {
		return null;
	}

	const name = file.file_name;
	const size = file.file_size;

	const transformedSize = formatSizeUnits(size);
	const fileNameOnly = name.split('.')[0];
	const fileType = `.${name.split('.')[1]}`;

	return (
		<StyledUploadedItem>
			<Typography
				variant="caption3"
				text={fileNameOnly}
				color={theme.color.general.dark}
				className="fileName"
			/>

			<Typography
				variant="caption3"
				text={fileType}
				color={theme.color.general.dark}
			/>

			<Typography
				variant="caption3"
				text={transformedSize}
				color={theme.color.general.dark}
				className="size"
				style={{ margin: '0 5px' }}
			/>

			<Icon
				name="cross"
				size={12}
				fill={theme.color.general.dark}
				onClick={deleteFile}
			/>
		</StyledUploadedItem>
	);
};

export default UploadedItem;