import React, { useContext } from 'react';

import { StyledWidgetStartHeader, StyledWidgetTitle } from '../styled';
import Icon from '../../../components/atomic/Icon/Icon';
import Typography from '../../../components/atomic/Typography';
import { ScreenType } from '../../../types';
import { LangContext } from '../../../LangProvider';

interface IProps {
	currentScreen: ScreenType;
	headerTextLangKey?: string,
	hideWidget: () => void,
	goOneScreenBack?: () => void,
	subtextKey: string,
}

const WidgetStartHeader = (props: IProps) => {
	const {
		currentScreen,
		headerTextLangKey,
		hideWidget,
		goOneScreenBack,
		subtextKey,
	} = props;

	const getTranslate = useContext(LangContext);
	const subtextKeyTranslate = getTranslate(subtextKey);

	const headerTextTranslate = getTranslate(headerTextLangKey || 'welcome');

	return (
		<StyledWidgetStartHeader>
			<StyledWidgetTitle>
				{currentScreen !== 'chooseChannels' && goOneScreenBack && (
					<Icon
						name="arrowLeft"
						className="arrowLeft"
						onClick={goOneScreenBack}
					/>
				)}
				<div className="titleTexts">
					<Typography
						variant="h3"
					>
						{headerTextTranslate}
					</Typography>
					{subtextKey && (
						<Typography
							className="subtext"
							variant="button1"
						>
							{subtextKeyTranslate}
						</Typography>
					)}
				</div>
			</StyledWidgetTitle>

			<Icon
				onClick={hideWidget}
				name="chevronDownThin"
				className="closeIcon"
			/>
		</StyledWidgetStartHeader>
	);
};

export default WidgetStartHeader;