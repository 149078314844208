import React, { Dispatch, SetStateAction, useContext } from 'react';

import { useTheme } from 'styled-components';
import Button from './atomic/Button';
import Loader from './atomic/Loader';
import Icon from './atomic/Icon/Icon';
import Typography from './atomic/Typography';
import UploadFile from './UploadFile';
import { IFileUploadResult } from '../types';
import { LangContext } from '../LangProvider';

interface IProps {
	inProgress: boolean,
	setFiles: Dispatch<SetStateAction<IFileUploadResult[]>>,
	setErrorText: Dispatch<SetStateAction<string>>,
	onEmojiClick: (e: React.FormEvent) => void,
	onSend: () => void,
}

const ActionButtons = (props: IProps) => {
	const {
		inProgress,
		onEmojiClick,
		onSend,
		setFiles,
		setErrorText,
	} = props;

	const theme = useTheme();
	const getTranslate = useContext(LangContext);
	const toAddNewLineTranslate = getTranslate('toAddNewLine');

	return (
		<>
			<Button
				disabled={inProgress}
				variant="general"
				iconLeftProps={{
					name: 'smile',
					className: 'withoutHover',
				}}
				className="textareaActionButton smileButton"
				onClick={onEmojiClick}
			/>

			<Button
				disabled={inProgress}
				variant="general"
				iconLeftProps={{
					name: 'attachSign',
					fill: theme.color.general.black,
					className: 'withoutHover',
				}}
				className="textareaActionButton attachButton"
			>
				<UploadFile
					setFiles={setFiles}
					setErrorText={setErrorText}
				/>
			</Button>

			<Button
				variant="general"
				disabled={inProgress}
				className="textareaActionButton sendButton"
				onClick={onSend}
			>
				{inProgress
					? <Loader color="#fff" />
					: <Icon name="mailSend" className="withoutHover" />}
			</Button>

			<Typography
				variant="caption3"
				className="prompt"
				color={theme.color.general.dark}
			>
				<b>SHIFT + ENTER</b>
				{' '}
				{toAddNewLineTranslate}
			</Typography>
		</>
	);
};

export default ActionButtons;