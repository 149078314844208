import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { IKnowledgeBaseArticleDetailsResponse } from '../../../types';
import { useWidget } from '../../../WidgetProvider';
import { getKnowledgeBaseArticle, sendKnowledgeBaseArticleInteract } from '../../../api';
import Feedback from './Feedback';

const Wrap = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: 10px;
  padding: 5px 10px 10px 10px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: ${({ theme }) => theme.color.general.darker};
  }

  &::-webkit-scrollbar-track {
    margin: 10px 0;
    border-radius: 4px;
    background: ${({ theme }) => theme.color.general.darkest}26;
  }
`;
const Title = styled.div`
  margin: 5px 0 20px 0;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  overflow-wrap: anywhere;
`;
const Content = styled.div`
  margin-bottom: 30px;
  font-size: 14px;
  line-height: 23px;
  overflow-wrap: anywhere;
  
  img {
    max-width: 100%;
    border-radius: 3px;
  }  
	
  ol {
    list-style-type: decimal;
    padding-left: 2.5em;
  }

  ul {
    list-style-type: disc;
    padding-left: 2.5em;
  }

  .ql-font-monospace {
    font-family: Monaco, "Courier New", monospace;
  }

  .ql-font-serif {
    font-family: Georgia, "Times New Roman", serif;
  }
	
  .ql-size-huge {
    font-size: 2.5em;
  }
	
  .ql-size-small {
    font-size: 0.75em;
  }

  .ql-size-large {
    font-size: 1.5em;
  }

  blockquote {
    margin: 5px 0;
    padding-left: 16px;
    border-left: 4px solid #ccc;
  }

  .ql-syntax {
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 5px 10px;
    overflow: visible;
    border-radius: 3px;
    background-color: #23241f;
    color: #f8f8f2;
    white-space: pre-wrap;
  }
`;

let timeStamp: number;

const Article = () => {
	const [data, setData] = useState<IKnowledgeBaseArticleDetailsResponse | null>(null);

	const { knowledgeBaseItemId, setKnowledgeBaseArticleLink } = useWidget();

	useEffect(() => {
		if (knowledgeBaseItemId) {
			getKnowledgeBaseArticle(knowledgeBaseItemId)
				.then(setData)
				.catch(() => null);
		} else {
			setData(null);
		}
	}, [knowledgeBaseItemId]);

	useEffect(() => {
		setKnowledgeBaseArticleLink(data?.user_page_link || '');
	}, [data?.user_page_link]);

	useEffect(() => {
		timeStamp = Date.now();

		if (knowledgeBaseItemId) {
			sendKnowledgeBaseArticleInteract(knowledgeBaseItemId, 'view')
				.then(() => null)
				.catch(() => null);
		}

		return () => {
			if (knowledgeBaseItemId) {
				const amount = Math.round((Date.now() - timeStamp) / 1000);

				sendKnowledgeBaseArticleInteract(knowledgeBaseItemId, 'time_spent', amount)
					.then(() => null)
					.catch(() => null);
			}
		};
	}, [knowledgeBaseItemId]);

	if (!data) {
		return <Wrap />;
	}

	const content = data.context
		.replace(/ href="/g, ' href="//')
		.replace(/\/\/http/g, 'http');

	return (
		<Wrap>
			<Title>{data.title}</Title>
			<Content dangerouslySetInnerHTML={{ __html: content }} />
			{data.article_interactions ? (
				<Feedback id={data.id} />
			) : null}
		</Wrap>
	);
};

export default Article;