import React, { Dispatch, SetStateAction, useContext } from 'react';
import styled from 'styled-components';
import { FILE_SIZE_LIMIT, FILE_TYPES } from '../constants';
import { uploadFile } from '../api';
import { useWidget } from '../WidgetProvider';
import { IFileUploadResult } from '../types';
import { LangContext } from '../LangProvider';

interface IProps {
	setFiles: Dispatch<SetStateAction<IFileUploadResult[]>>
	setErrorText: Dispatch<SetStateAction<string>>
}

const InputBlock = styled.input`
  position: absolute;
  inset: 0;
  opacity: 0;
`;

const UploadFile = (props: IProps) => {
	const { setFiles, setErrorText } = props;

	const { license, contactUid } = useWidget();
	const getTranslate = useContext(LangContext);
	const fileInvalidTypeTranslate = getTranslate('fileInvalidType');
	const fileTooLargeTranslate = getTranslate('fileTooLarge');

	const accept = Object.keys({ ...FILE_TYPES.images, ...FILE_TYPES.files });
	const maxLength = FILE_SIZE_LIMIT;

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			const file = e.target.files[0];

			if (!(accept.includes(file.type))) {
				setErrorText(fileInvalidTypeTranslate);
			} else if (file.size > maxLength) {
				setErrorText(fileTooLargeTranslate);
			} else {
				setErrorText('');
				uploadFile(license, contactUid, file)
					.then((res) => {
						setFiles((prev) => [...prev, res]);
					});
			}
		}
	};

	return (
		<InputBlock
			type="file"
			accept={accept.join(', ')}
			maxLength={maxLength}
			onChange={handleChange}
			onClick={(e) => {
				// @ts-ignore
				e.target.value = null;
			}}
		/>
	);
};

export default UploadFile;