import React, {
	useCallback, useEffect, useRef, useState,
} from 'react';
import clsx from 'clsx';
import { Virtuoso } from 'react-virtuoso';

import { StyledChatArea } from './styled';
import ChatAction from './ChatAction';
import Button from './atomic/Button';
import { getChatHistory } from '../api';
import { CHAT_EVENTS_LOAD_LIMIT, CHAT_EVENTS_TO_SHOW_IN_WIDGET } from '../constants';
import { useLiveChat } from '../LiveChatProvider';
import { useWidget } from '../WidgetProvider';
import { usePrevious, useUnmount } from '../hooks/useReact';
import { IChatEventBrief, ScreenType } from '../types';

interface IPros {
	currentScreen: ScreenType,
	chatHistory: IChatEventBrief[],
	operatorOnline: boolean,
	license: string,
	contactUid: string,
}

let scrollOnOpenTimeoutIdRef: NodeJS.Timeout | undefined;

let firstLoadRef = false;

const ChatArea = (props: IPros) => {
	const {
		currentScreen,
		chatHistory,
		operatorOnline,
		license,
		contactUid,
	} = props;

	const { widgetOpened } = useWidget();
	const {
		setHistory,
		totalHistoryCount,
		currentHistoryCount,
		setCurrentHistoryCount,
		eventJustSent,
		eventJustReceived,
		setEventJustSent,
		setEventJustReceived,
	} = useLiveChat();

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const gridRef = useRef<any>(null);

	const allMessagesLoadedUp = currentHistoryCount === totalHistoryCount;

	const chatHistoryLengthPrev = usePrevious(currentHistoryCount);
	const historyCountForTimeout = useRef(currentHistoryCount);

	useEffect(() => {
		historyCountForTimeout.current = currentHistoryCount;
	}, [currentHistoryCount]);

	const [loadOffset, setLoadOffset] = useState(0);

	const [atBottom, setAtBottom] = useState(false);
	const [firstItemIndex, setFirstItemIndex] = useState(0);

	const prevAtBottom = usePrevious(atBottom);

	useEffect(() => {
		setFirstItemIndex(totalHistoryCount);
	}, [totalHistoryCount]);

	const prependItems = useCallback(
		() => {
			if (!firstItemIndex || allMessagesLoadedUp) {
				return undefined;
			}

			const nextFirstItemIndex = firstItemIndex - CHAT_EVENTS_LOAD_LIMIT;

			setFirstItemIndex(() => (nextFirstItemIndex > 0 ? nextFirstItemIndex : 0));

			setLoadOffset((prev) => prev + CHAT_EVENTS_LOAD_LIMIT);

			getChatHistory(license, contactUid, loadOffset + CHAT_EVENTS_LOAD_LIMIT).then((res) => {
				if (res && res.items) {
					const itemsFiltered = res
						.items.filter((item) => CHAT_EVENTS_TO_SHOW_IN_WIDGET.includes(item.type)) || [];

					setCurrentHistoryCount((prev) => (prev + res.items.length));
					setHistory((prev) => [...itemsFiltered.reverse(), ...prev]);
				}
			});
			return false;
		},
		[
			firstItemIndex,
			allMessagesLoadedUp,
			license,
			contactUid,
			loadOffset,
			setCurrentHistoryCount,
			setHistory,
		],
	);

	const scrollToTheLastItem = useCallback(() => {
		if (gridRef.current?.scrollToIndex) {
			gridRef.current.scrollToIndex({ index: historyCountForTimeout.current, behavior: 'auto' }); // or true
		}
	}, [historyCountForTimeout]);

	useEffect(() => {
		if (widgetOpened && !firstLoadRef) {
			scrollOnOpenTimeoutIdRef = setTimeout(() => {
				scrollToTheLastItem();
				firstLoadRef = true;
			}, 800);
		}

		if (!widgetOpened) {
			firstLoadRef = false;
		}
	}, [scrollToTheLastItem, widgetOpened, currentScreen]);

	useUnmount(() => {
		clearTimeout(scrollOnOpenTimeoutIdRef);
	});

	useEffect(() => {
		// for first and second system messages with delay
		if (currentHistoryCount > (chatHistoryLengthPrev || 0) && !eventJustSent) {
			gridRef.current.scrollToIndex({ index: currentHistoryCount, behavior: 'auto' });
		}
	}, [chatHistoryLengthPrev, currentHistoryCount]);

	useEffect(() => {
		if (eventJustSent && currentHistoryCount === chatHistoryLengthPrev) {
			gridRef.current.scrollToIndex({ index: currentHistoryCount, behavior: 'auto' });
			setEventJustSent('');
		}
	}, [chatHistoryLengthPrev, currentHistoryCount, setEventJustSent]);

	useEffect(() => {
		if (prevAtBottom && !atBottom && eventJustReceived) {
			gridRef.current?.scrollToIndex({ index: currentHistoryCount, behavior: 'auto' });
			setEventJustReceived('');
		}
	}, [prevAtBottom, atBottom, currentHistoryCount, eventJustReceived, setEventJustReceived]);

	const itemContent = (index: number, historyEvent: IChatEventBrief) => (
		<ChatAction
			key={index}
			data={historyEvent}
			operatorOnline={operatorOnline}
		/>
	);

	const atBottomStateChange = (bottom: boolean) => {
		setAtBottom(bottom);
		if (atBottom) {
			clearTimeout(scrollOnOpenTimeoutIdRef);
			scrollOnOpenTimeoutIdRef = undefined;
		}
	};

	return (
		<StyledChatArea>
			<Virtuoso
				style={{ height: '100%' }}
				data={chatHistory}
				ref={gridRef}
				alignToBottom
				firstItemIndex={firstItemIndex}
				startReached={prependItems}
				initialTopMostItemIndex={currentHistoryCount || 1}
				atTopThreshold={50}
				atBottomStateChange={atBottomStateChange}
				itemContent={itemContent}
			/>
			<Button
				className={clsx(atBottom ? 'hide' : 'displayed', 'scrollButton')}
				borderRadius="50%"
				iconRightProps={{
					name: 'paginationArrow', width: 16, height: 16, margin: '0',
				}}
				onClick={scrollToTheLastItem}
				disabled={atBottom}
			/>
		</StyledChatArea>
	);
};

export default ChatArea;