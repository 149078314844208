import React, { Dispatch, SetStateAction, useContext } from 'react';

import styled from 'styled-components';
import { StyledHeader } from './styled';
import Icon from './atomic/Icon';
import Typography from './atomic/Typography';
import { useSendEventWebsocket } from '../hooks/useWidgetWebsocket';
import { useLiveChat } from '../LiveChatProvider';
import { useWidget } from '../WidgetProvider';
import { ChatEvent, ScreenType } from '../types';
import { getIsPresentKnowledgeBase } from '../utils/utils';
import Dropdown from './atomic/Dropdown';
import FlexRow from './atomic/FlexRow';
import { LangContext } from '../LangProvider';

const ButtonBlock = styled.div`
  display: flex;
`;
const TitleBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

interface IProps {
	goOneScreenBack?: () => void,
	offlineText: string | false,
	setCurrentScreen?: Dispatch<SetStateAction<ScreenType>>,
}

const WidgetHeader = (props: IProps) => {
	const { goOneScreenBack, offlineText, setCurrentScreen } = props;

	const { setWidgetOpened, knowledgeBaseResponse, knowledgeBaseItemId } = useWidget();
	const {
		setChatStarted,
		setHistoryEvent,
		chatId,
		isSoundOn,
		onToggleSounds,
		chatStatus,
	} = useLiveChat();

	const getTranslate = useContext(LangContext);

	const onCloseChat = () => {
		// eslint-disable-next-line no-use-before-define
		sendEventToServer({ chat_id: chatId, type: 'chat_closed' });
	};

	const onCloseChatSuccess = (eventType: string, callback: () => void) => {
		if (eventType === 'chat_closed') {
			setHistoryEvent({
				type: ChatEvent.chat_closed,
				chat_id: chatId,
				// @ts-ignore
				data: {
					// @ts-ignore
					chat_id: chatId,
					type: ChatEvent.chat_closed,
				},
			});
			setChatStarted(false);

			callback();
		}
	};

	const toggleSounds = () => {
		onToggleSounds();
	};

	const { sendEventToServer, onSendEvent } = useSendEventWebsocket(onCloseChat, onCloseChatSuccess);

	const onCloseWidget = () => {
		setWidgetOpened(false);
	};

	const isPresentKnowledgeBase = getIsPresentKnowledgeBase(knowledgeBaseResponse);

	const handleClickKnowledgeBase = () => {
		if (setCurrentScreen) {
			setCurrentScreen(knowledgeBaseItemId ? 'knowledgeBaseItem' : 'knowledgeBaseList');
		}
	};

	const endChatText = <Typography text={getTranslate('endChat')} />;
	const soundText = <Typography text={getTranslate(isSoundOn ? 'disableSounds' : 'enableSounds')} />;

	const offlineTextTranslated = getTranslate(offlineText || '');

	return (
		<StyledHeader offlineText={!!offlineText}>
			{goOneScreenBack && <Icon name="arrowLeft" className="arrowLeft" onClick={goOneScreenBack} />}
			<TitleBlock>
				<Typography variant="h3" className="title">
					{getTranslate('activeChat')}
				</Typography>
				{offlineText && (
					<Typography className="subtext" variant="button1">
						{offlineTextTranslated}
					</Typography>
				)}
			</TitleBlock>

			<ButtonBlock>
				<div>
					<Dropdown className="dropdown">
						<FlexRow className="dropdownItem" onClick={toggleSounds}>
							<Icon name={isSoundOn ? 'disableSounds' : 'enableSounds'} className="dropdownIcon" />
							{soundText}
						</FlexRow>

						{chatStatus === 'open' && (
							<FlexRow className="dropdownItem" onClick={onSendEvent('chat_closed')}>
								<Icon name="crossBubble" className="dropdownIcon" />
								{endChatText}
							</FlexRow>
						)}
					</Dropdown>
				</div>

				{isPresentKnowledgeBase ? (
					<Icon
						onClick={handleClickKnowledgeBase}
						name="knowledgeBase"
						className="closeIcon"
					/>
				) : null}
				<Icon onClick={onCloseWidget} name="chevronDownThin" className="closeIcon" />
			</ButtonBlock>
		</StyledHeader>
	);
};

export default WidgetHeader;