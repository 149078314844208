import React from 'react';
import { useTranslation } from './hooks/useTranslation';

export const LangContext = React.createContext<(key: string, args?: Record<string, string>) => string>(() => '');

interface IProps {
	children: React.ReactNode,
}

const LangProvider = (props: IProps) => {
	const { children } = props;

	return (
		<LangContext.Provider value={useTranslation}>
			{ children }
		</LangContext.Provider>
	);
};

export default LangProvider;